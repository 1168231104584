<template>
  <el-container style="height: 100vh">
    <el-menu
      :default-active="$route.path.substring(1)"
      style="z-index: 3; user-select: none"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item index="homePage">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-menu-item index="checkIn">
        <i class="el-icon-s-check"></i>
        <span slot="title">签到打卡</span>
      </el-menu-item>
      <el-menu-item index="newsManage">
        <i class="el-icon-news"></i>
        <span slot="title">新闻管理</span>
      </el-menu-item>
    </el-menu>

    <el-container>
      <el-header
        style="
          text-align: right;
          font-size: 12px;
          z-index: 2;
          user-select: none;
        "
      >
        <i
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          style="
            float: left;
            line-height: 60px;
            font-size: 30px;
            cursor: pointer;
          "
          @click="isCollapse = !isCollapse"
        ></i>
        <el-dropdown @command="handleCommand">
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logOut">登出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>{{ account }}</span>
      </el-header>

      <el-main>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  mounted() {
    this.account = localStorage.getItem("account");
    console.log(this.$route.path.substr(1));
    this.$route.path.substr(1).toLowerCase() === "home" &&
      this.$router.replace("/homePage");
  },
  data() {
    return {
      isCollapse: true,
      account: "",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCommand(command) {
      if (command === "logOut") {
        localStorage.setItem('account', '');
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.el-main {
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
}
.el-menu {
  border-right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgb(0, 0, 0);
}
</style>
<style>
.el-header {
  background-color: #ffffff;
  color: rgb(99, 99, 99);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
  line-height: 60px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
